import { Auth } from 'aws-amplify';
import PNotify from "pnotify/dist/es/PNotify";
import "pnotify/dist/es/PNotifyButtons";
import "pnotify/dist/es/PNotifyConfirm";
import "pnotify/dist/es/PNotifyCallbacks";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

if (typeof window.stackBottomRight === 'undefined') {
    window.stackBottomRight = {
        'dir1': 'up',
        'dir2': 'left',
        'firstpos1': 25,
        'firstpos2': 25
    };
}

const isAuthenticated = () => new Promise(resolve => {
    Auth.currentAuthenticatedUser().then(user => resolve(true)).catch(err => resolve(false));
});

const getToken = () => new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
        user.getSession((err, session) => {
            if (err) {
                alert('an unexpected error occured');
                console.log(err);
            } else {
                resolve(session.getIdToken().getJwtToken());
            }
        });
    }).catch(reject);
});

const postData = path => data => new Promise(async resolve => {
    axios.post(window.API_GATEWAY_ENDPOINT + path, data, {
        headers: (await isAuthenticated()) ? { Authorization: await getToken() }:{}
    }).then(({ data }) => {
        if (data.message === 'general error') {
            alert('An unexpected error occured');
            console.log(data.error);
            console.log('event : ');
            console.log(JSON.stringify(data.event));
        }
        resolve(data);
    }).catch(err => {
        alert('An unexpected error occured');
        console.log(err);
    });
});

export default {
    getToken: getToken,
    getShortUrl: link => postData('/links')({ link }),

    capitalizeFirstLetter: s => s.charAt(0).toUpperCase() + s.slice(1),
    isAuthenticated: isAuthenticated,

    getFirstname: () => Auth.user.attributes['custom:firstname'],
    getLastname: () => Auth.user.attributes['custom:lastname'],
    getEmail: () => Auth.user.attributes.email,

    notify: PNotify,

    logout: () => new Promise(resolve => {
        Auth.signOut().then(resolve).catch(err => console.log(err));
    }),

    signIn: (email, password) => Auth.signIn({
        username: email,
        password: password
    })
}
